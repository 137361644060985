import { Configuration } from '@azure/msal-browser/dist/config/Configuration';
import { RedirectRequest } from '@azure/msal-browser/dist/request/RedirectRequest';
import { routes } from 'src/app/+auth/routes.const';
import { getEnvValue } from 'src/app/shared/helpers/env.helper';

const FREDENSBORG_BOLIG_CLIENT_ID = getEnvValue('FREDENSBORG_BOLIG_CLIENT_ID');
const FREDENSBORG_BOLIG_TENANT_ID = getEnvValue('FREDENSBORG_BOLIG_TENANT_ID');
const HEIMSTADEN_CLIENT_ID = getEnvValue('HEIMSTADEN_CLIENT_ID');
const HEIMSTADEN_TENANT_ID = getEnvValue('HEIMSTADEN_TENANT_ID');

type Config = {
  msalConfig: { [key: string]: Configuration };
  loginRequest: RedirectRequest;
};

export const CONFIG: Config = {
  msalConfig: {
    heimstaden: {
      auth: {
        authority: `https://login.microsoftonline.com/${HEIMSTADEN_TENANT_ID}/`,
        clientId: HEIMSTADEN_CLIENT_ID,
        redirectUri: routes.SIGN_IN,
      },
    },
    fredensborgBolig: {
      auth: {
        authority: `https://login.microsoftonline.com/${FREDENSBORG_BOLIG_TENANT_ID}/`,
        clientId: FREDENSBORG_BOLIG_CLIENT_ID,
        redirectUri: routes.SIGN_IN,
      },
    },
  },
  loginRequest: {
    scopes: ['openid', 'User.Read', 'offline_access'],
  },
};
