import { lazy } from 'react';
import { AuthGuard, authRoutes, GuestGuard } from 'src/app/+auth';
import { dashboardRoutes } from 'src/app/+dashboard';
import { Routes } from 'src/app/shared/routing/routes.type';

export const getRoutes = (): Routes => [
  {
    component: lazy(() => import('src/app/+auth/SignIn/sign-in.component')),
    exact: true,
    guard: GuestGuard,
    path: authRoutes.SIGN_IN,
  },
  {
    path: '*',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: dashboardRoutes.INDEX,
        component: lazy(() => import('../../+dashboard/dashboard.component')),
      },
      {
        exact: true,
        path: dashboardRoutes.BENEFITS,
        component: lazy(
          () => import('src/app/+dashboard/Benefits/benefits.component'),
        ),
      },
    ],
  },
];
