import React, { Fragment, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { generateUniqId } from 'src/app/shared/helpers';
import { Routes } from 'src/app/shared/routing/routes.type';

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<></>}>
    <Switch>
      {routes.map(({ component, exact, guard, path, routes }) => {
        const Guard = guard || Fragment;
        const Component = component || Fragment;

        return (
          <Route
            key={generateUniqId()}
            path={path}
            exact={exact}
            render={(props) => (
              <Guard>
                {routes ? renderRoutes(routes) : <Component {...props} />}
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);
