import { HttpClient } from 'src/app/shared/http/http.client';
import { httpInstance } from 'src/app/shared/http/http.instance';

export const endpoints = {
  BENEFITS: 'jqfihmg1ep257qjiyr6guyyx8h56npk8',
  IS_INVITED: 'bkq9aluh1cy87hkrbhcy9f97iaeczpbb',
};

const integromatInstance = httpInstance({
  baseURL: 'https://hook.integromat.com/',
});
export const integromatClient = new HttpClient(integromatInstance);
