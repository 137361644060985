import { FormikConfig, useFormik } from 'formik';
import { isEmpty } from 'lodash-es';
import isEqual from 'lodash-es/isEqual';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { endpoints, integromatClient } from 'src/app/+auth/integromat.client';
import {
  HelperText,
  Input,
  InputWrapper,
} from 'src/app/+auth/shared/components/InvitedForm/invited-form.styles';
import { useAuth } from 'src/app/+auth/use-auth.hook';
import { Button, Card, User } from 'src/app/shared';
import * as Yup from 'yup';

type Values = {
  email: string;
};
type Props = {
  setTitle: (title: string) => void;
};

export const InvitedForm: FC<Props> = ({ setTitle }) => {
  const { loginInvitedUser } = useAuth();
  const [t] = useTranslation();
  const [isGlobalError, setGlobalError] = useState(false);
  const config: FormikConfig<Values> = {
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      try {
        const { data } = await integromatClient.post<Values, User>(
          endpoints.IS_INVITED,
          values,
        );

        await loginInvitedUser(data);
      } catch (e) {
        const getErrorTitle = () => {
          switch (Number(e.errorCode)) {
            case 200: {
              return 'auth.invited.noValidTitle';
            }

            default: {
              return 'auth.invited.notFoundTitle';
            }
          }
        };
        setGlobalError(true);
        setTitle(getErrorTitle());
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('fieldEmailValid').required('fieldRequired'),
    }),
  };
  const {
    errors,
    handleChange,
    handleSubmit,
    initialValues,
    isSubmitting,
    values,
  } = useFormik(config);
  const isDisabled =
    !isEmpty(errors) || isSubmitting || isEqual(initialValues, values);

  return isGlobalError ? (
    <Card disabled />
  ) : (
    <form onSubmit={handleSubmit} noValidate>
      <InputWrapper>
        <Input
          disabled={isSubmitting}
          id="email"
          onChange={handleChange}
          type="text"
          value={values.email}
        />
        {errors['email'] && <HelperText>{t(errors['email'])}</HelperText>}
      </InputWrapper>
      <Button
        disabled={isDisabled}
        label="auth.buttonLabel.general"
        type="submit"
      />
    </form>
  );
};
