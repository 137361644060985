import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

export const InputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const Input = styled.input`
  background-color: ${palette.white};
  box-shadow: 0 4px 16px -4px ${palette.bone};
  color: ${palette.mineShaft};
  font-size: 24px;
  line-height: 28px;
  padding: 16px;
  width: 100%;
`;
export const HelperText = styled.span`
  display: block;
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  margin-top: 8px;
`;
