import { palette } from 'src/app/shared/consts';
import styled from 'styled-components';

export const Spinner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;

  &:before {
    animation: loadingspin 1s linear infinite;
    border-radius: 50%;
    border: 0.4em solid transparent;
    border-top-color: ${palette.mineShaft};
    content: '';
    height: 2.5em;
    pointer-events: none;
    width: 2.5em;
  }

  @keyframes loadingspin {
    100% {
      transform: rotate(360deg);
    }
  }
`;
