import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { authRoutes, useAuth } from 'src/app/+auth';

export const AuthGuard: FC = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to={authRoutes.SIGN_IN} />;
  }

  return <>{children}</>;
};
