import { Action, AuthState } from 'src/app/+auth/auth.type';

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  user: {
    displayName: '',
    email: '',
    id: '',
    validTo: '',
  },
};

export const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: initialAuthState.user,
      };
    }
    default: {
      return { ...state };
    }
  }
};
