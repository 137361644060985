import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import arrowLeft from 'src/app/assets/arrow-left.svg';
import { BackButton, BackButtonLink, Icon, Wrapper } from './back.styles';

type Props = {
  text: string;
  to?: string;
  onClick?: () => void;
};

export const Back: FC<Props> = ({ onClick, text, to }) => {
  const [t] = useTranslation();
  const content = (
    <>
      <Icon src={arrowLeft} alt="" />
      {t(text)}
    </>
  );

  return (
    <Wrapper>
      {onClick && <BackButton onClick={onClick}>{content}</BackButton>}
      {to && <BackButtonLink to={to}>{content}</BackButtonLink>}
    </Wrapper>
  );
};
