import { getEnvValue } from 'src/app/shared/helpers/env.helper';
import { HttpClient } from 'src/app/shared/http/http.client';
import { httpInstance } from 'src/app/shared/http/http.instance';

const accessToken = window.localStorage.getItem('graph_token');

const azureInstance = httpInstance({
  baseURL: getEnvValue('MICROSOFT_GRAPH_BASE_URL'),
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});
export const azureClient = new HttpClient(azureInstance);
