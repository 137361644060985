import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'src/app/+auth';
import { dashboardRoutes } from 'src/app/+dashboard';

interface Props {
  children?: ReactNode;
}

export const GuestGuard: FC<Props> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={dashboardRoutes.INDEX} />;
  }

  return <>{children}</>;
};
