import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Title } from './intro.styles';

type Props = {
  text: string;
  title: string;
};

export const Intro: FC<Props> = ({ text, title }) => {
  const [t] = useTranslation();

  return (
    <>
      <Title>{t(title)}</Title>
      <Text>{t(text)}</Text>
    </>
  );
};
