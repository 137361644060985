import { Link as RouterLink } from 'react-router-dom';
import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

const linkStyles = `
  background-color: ${palette.outerSpace};
  border: unset;
  box-shadow: 0 4px 16px -4px ${palette.bone};
  color: ${palette.white};
  display: flex;
  font-family: 'Segoe UI', sans-serif;
  font-size: 1.5rem;
  justify-content: space-between;
  line-height: 1.4rem;
  margin-top: 16px;
  padding: 16px;
  width: 100%;
`;

export const Link = styled(RouterLink)`
  ${linkStyles}
`;
export const NativeLink = styled.a`
  ${linkStyles}
`;
export const Label = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  white-space: pre-line;
`;
export const Text = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin-top: 4px;
`;
export const Icon = styled.img`
  margin-left: 16px;
`;
