import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

export const Container = styled.div<{ disabled?: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? 'unset' : palette.outerSpace};
  border-radius: 32px;
  margin-top: 16px;
  overflow: hidden;
  position: relative;
  display: inline-block;
`;
export const Card = styled.div`
  background-color: ${palette.white};
  border-radius: 8px;
  padding: 8px;
  position: absolute;
  left: 16px;
`;
export const Status = styled(Card)`
  top: 24px;
`;
export const Data = styled(Card)`
  bottom: 24px;
`;
export const Name = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
`;
export const Id = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
`;
export const ErrorMessage = styled.p`
  font-weight: 300;
  margin-top: 16px;
`;
