import { Link } from 'react-router-dom';
import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 48px;
`;
export const BackButton = styled.button`
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 20px;
  padding: 0;
`;
export const BackButtonLink = styled(Link)`
  align-items: center;
  color: ${palette.black};
  display: flex;
`;
export const Icon = styled.img`
  margin-right: 16px;
`;
