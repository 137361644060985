import React, { FC } from 'react';
import { AuthProvider } from 'src/app/+auth';
import {
  Container,
  Content,
  Footer,
  Header,
  Main,
} from 'src/app/shared/components/Layout/layout.styles';
import { Logo } from 'src/app/shared/components/Logo/logo.component';

export const Layout: FC = ({ children }) => {
  return (
    <Main>
      <Header>
        <Container>
          <Logo />
        </Container>
      </Header>
      <AuthProvider>
        <Content>{children}</Content>
      </AuthProvider>
      <Footer>
        <Container>
          <Logo reverted />
        </Container>
      </Footer>
    </Main>
  );
};
