import { createContext } from 'react';
import { initialAuthState } from 'src/app/+auth/auth.reducer';
import { AuthContextValue } from 'src/app/+auth/auth.type';

export const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  loginInvitedUser: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  method: 'AzureAD',
});
