import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

export const Main = styled.main`
  background-color: ${palette.mercury};
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const Container = styled.div`
  margin: 0 auto;
  max-width: 900px;
  padding: 0 16px;
  width: 100%;
`;
export const Content = styled(Container)`
  margin: 24px auto 48px;
  flex: 1;
`;
export const Header = styled.header`
  padding: 24px 0;
`;
export const Footer = styled.footer`
  background-color: ${palette.outerSpace};
  margin-top: auto;
  padding: 16px 0;
  text-align: center;
`;
