import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import no from 'src/translations/no.json';

const translationsContext = 'TRANSLATIONS';
const DEFAULT_LANGUAGE = 'no';

export const initTranslations = async (): Promise<void> => {
  await i18n.use(initReactI18next).init({
    defaultNS: translationsContext,
    lng: DEFAULT_LANGUAGE,
    interpolation: { escapeValue: false },
    fallbackLng: {
      nb: [DEFAULT_LANGUAGE],
      nn: [DEFAULT_LANGUAGE],
      default: [DEFAULT_LANGUAGE],
    },
    resources: {
      no: { [translationsContext]: no },
    },
  });
};
