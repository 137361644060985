import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React, { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorPage, getRoutes, renderRoutes } from 'src/app/shared';
import { Layout } from 'src/app/shared/components/Layout/layout.component';
import { initTranslations } from 'src/app/shared/services';

const AppComponent: FC = () => {
  const routes = getRoutes();

  useEffect(() => {
    initTranslations();
  }, []);

  return (
    <BrowserRouter>
      <SentryErrorBoundary fallback={ErrorPage} showDialog>
        <Layout>{renderRoutes(routes)}</Layout>
      </SentryErrorBoundary>
    </BrowserRouter>
  );
};

export default AppComponent;
