import { AxiosError } from 'axios';
import camelCase from 'lodash-es/camelCase';
import snakeCase from 'lodash-es/snakeCase';

type TransformType = 'CAMEL_CASE' | 'SNAKE_CASE';

const getTransformByType = (
  type: TransformType,
): typeof camelCase | typeof snakeCase => {
  switch (type) {
    case 'SNAKE_CASE': {
      return snakeCase;
    }

    case 'CAMEL_CASE':
    default: {
      return camelCase;
    }
  }
};

export const transformKeys = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj: object,
  type: TransformType,
  // eslint-disable-next-line @typescript-eslint/ban-types
): object | undefined => {
  if (!obj) {
    return undefined;
  }

  if (Array.isArray(obj)) {
    return obj.map((v) => transformKeys(v, type));
  }

  if (obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [getTransformByType(type)(key)]: transformKeys(obj[key], type),
      }),
      {},
    );
  }

  return obj;
};

export const handleError = (error: AxiosError): Promise<unknown> => {
  if (!error.response) {
    return Promise.reject(error);
  }

  switch (error.response.status) {
    default: {
      return Promise.reject(transformKeys(error.response.data, 'CAMEL_CASE'));
    }
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const handleAPIErrors = (errors: object): object => {
  const obj = {};

  Object.entries(errors).forEach(([key, error]) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    obj[key] = error;
  });

  return obj;
};
