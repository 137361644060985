import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { handleError, transformKeys } from './http.helper';

export const httpInstance = (config: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create(config);

  instance.interceptors.response.use(
    (response: AxiosResponse) => ({
      ...response,
      data: transformKeys(response.data, 'CAMEL_CASE'),
    }),
    handleError,
  );
  instance.interceptors.request.use(
    (request: AxiosRequestConfig) => ({
      ...request,
      data: transformKeys(request.data, 'SNAKE_CASE'),
    }),
    Promise.reject,
  );

  return instance;
};
