import { palette } from 'src/app/shared/consts/palette.const';
import styled from 'styled-components';

export const ButtonContained = styled.button`
  background-color: ${palette.outerSpace};
  box-shadow: 0 4px 16px -4px ${palette.bone};
  width: 100%;
  text-align: center;
  padding: 16px;
  font-size: 24px;
  line-height: 28px;
  color: ${palette.white};
  border: unset;
  font-family: 'Segoe UI', sans-serif;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:focus {
    outline: none;
  }
`;
export const ButtonText = styled.button`
  align-items: center;
  background: unset;
  border: unset;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  white-space: pre-line;
`;
export const Icon = styled.img`
  margin-left: 16px;
`;
