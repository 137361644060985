import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpMethod } from './http.type';

export class HttpClient {
  public instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  public delete<PAYLOAD, RESPONSE>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.request<PAYLOAD, RESPONSE>(
      url,
      HttpMethod.DELETE,
      undefined,
      config,
    );
  }

  public get<RESPONSE>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.request<undefined, RESPONSE>(
      url,
      HttpMethod.GET,
      undefined,
      config,
    );
  }

  public patch<PAYLOAD, RESPONSE>(
    url: string,
    data: PAYLOAD,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.request<PAYLOAD, RESPONSE>(url, HttpMethod.PATCH, data, config);
  }

  public post<PAYLOAD, RESPONSE>(
    url: string,
    data: PAYLOAD,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.request<PAYLOAD, RESPONSE>(url, HttpMethod.POST, data, config);
  }

  public put<PAYLOAD, RESPONSE>(
    url: string,
    data: PAYLOAD,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.request<PAYLOAD, RESPONSE>(url, HttpMethod.PUT, data, config);
  }

  private request<PAYLOAD, RESPONSE>(
    url: string,
    method: HttpMethod,
    data?: PAYLOAD,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<RESPONSE>> {
    return this.instance.request<RESPONSE>({
      ...config,
      data,
      headers: this.instance.defaults.headers,
      method,
      url,
    });
  }
}
