import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 36px;
  margin: 0 0 8px;
`;
export const Text = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 12px;
  white-space: pre-line;
`;
