import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import arrowRightBlack from 'src/app/assets/arrow-right-black.svg';
import {
  ButtonContained,
  ButtonText,
  Icon,
} from 'src/app/shared/components/buttons/Button/button.styles';

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  type?: 'submit';
  variant?: 'contained' | 'text';
};

export const Button: FC<Props> = (props) => {
  const { label, variant = 'contained', ...rest } = props;
  const [t] = useTranslation();
  const labelTemplate = <span>{t(label)}</span>;

  return variant === 'contained' ? (
    <ButtonContained {...rest}>{labelTemplate}</ButtonContained>
  ) : (
    <ButtonText {...rest}>
      {labelTemplate}
      <Icon src={arrowRightBlack} alt="" />
    </ButtonText>
  );
};
