import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/app/+auth';
import { routes } from 'src/app/+dashboard/routes.const';
import { Back } from 'src/app/+dashboard/shared';
import cardBackground from 'src/app/assets/card.svg';
import disableCardBackground from 'src/app/assets/disabled_card.svg';
import {
  Container,
  Data,
  ErrorMessage,
  Id,
  Name,
  Status,
} from 'src/app/shared/components/Card/card.styles';

type Props = {
  disabled?: boolean;
};

export const Card: FC<Props> = ({ disabled }) => {
  const { user } = useAuth();
  const { displayName, id, validTo } = user;
  const [t] = useTranslation();

  return (
    <>
      <Container disabled={disabled}>
        <img src={disabled ? disableCardBackground : cardBackground} alt="" />
        {!disabled && (
          <>
            <Status>
              {t('dashboard.status')}{' '}
              {validTo && t('dashboard.til', { date: validTo })}
            </Status>
            <Data>
              <Name>{displayName}</Name>
              <Id>{id}</Id>
            </Data>
          </>
        )}
      </Container>
      {disabled && (
        <>
          <Back text="benefits.backLabel" to={routes.INDEX} />
          <ErrorMessage>{t('auth.invited.errorInfo')}</ErrorMessage>
        </>
      )}
    </>
  );
};
