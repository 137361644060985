import { PublicClientApplication } from '@azure/msal-browser';
import { captureException } from '@sentry/react';
import React, { FC, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { initialAuthState, reducer } from 'src/app/+auth/auth.reducer';
import { azureClient } from 'src/app/+auth/azure.client';
import { CONFIG } from 'src/app/+auth/config';
import { routes } from 'src/app/+auth/routes.const';
import { getEnvValue, Spinner, User } from 'src/app/shared';
import { AuthContext } from '../auth.context';

const heimstadenInstance = new PublicClientApplication(
  CONFIG.msalConfig.heimstaden,
);
const fredensborgBoligInstance = new PublicClientApplication(
  CONFIG.msalConfig.fredensborgBolig,
);
const setSession = (graphToken: string | null): void => {
  if (graphToken) {
    localStorage.setItem('graph_token', graphToken);
    azureClient.instance.defaults.headers.Authorization = `Bearer ${graphToken}`;
  } else {
    sessionStorage.clear();
    localStorage.clear();

    delete azureClient.instance.defaults.headers.Authorization;
  }
};
const getInstance = (tenantId: string): PublicClientApplication => {
  switch (tenantId) {
    case getEnvValue('FREDENSBORG_BOLIG_TENANT_ID'): {
      return fredensborgBoligInstance;
    }
    case getEnvValue('HEIMSTADEN_TENANT_ID'): {
      return heimstadenInstance;
    }
    default: {
      return new PublicClientApplication({ auth: { clientId: '' } });
    }
  }
};

export const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const loginInvitedUser = async (payload: User): Promise<void> => {
    try {
      dispatch({
        type: 'LOGIN',
        payload,
      });
    } catch (e) {
      captureException(e);
    }
  };
  const login = async (isFredensborgBolig?: boolean): Promise<void> => {
    const instance = isFredensborgBolig
      ? fredensborgBoligInstance
      : heimstadenInstance;

    try {
      await instance.handleRedirectPromise();
      await instance.loginRedirect(CONFIG.loginRequest);
    } catch (e) {
      captureException(e);
    }
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    history.push({ pathname: routes.SIGN_IN });
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);

      try {
        await fredensborgBoligInstance.handleRedirectPromise();
        await heimstadenInstance.handleRedirectPromise();

        const [fredensborgBoligAccounts, heimstadenAccounts] =
          await Promise.all([
            fredensborgBoligInstance.getAllAccounts(),
            heimstadenInstance.getAllAccounts(),
          ]);

        if (
          fredensborgBoligAccounts.length === 0 ||
          heimstadenAccounts.length === 0
        ) {
          return;
        }

        const account = fredensborgBoligAccounts[0] || heimstadenAccounts[0];
        const { tenantId } = account;
        const instance = getInstance(tenantId);

        const { accessToken } = await instance.acquireTokenSilent({
          ...CONFIG.loginRequest,
          account,
        });

        setSession(accessToken);

        const { data } = await azureClient.get<User>(
          'me/?$select=displayName,email,id',
        );

        dispatch({
          type: 'LOGIN',
          payload: data,
        });
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []);

  return !isLoading ? (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        loginInvitedUser,
        logout,
        method: 'AzureAD',
      }}
    >
      {children}
    </AuthContext.Provider>
  ) : (
    <Spinner />
  );
};
