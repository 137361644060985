import { captureException } from '@sentry/react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'src/app/+auth';
import { InvitedForm } from 'src/app/+auth/shared';
import { dashboardRoutes } from 'src/app/+dashboard';
import { Button, Intro } from 'src/app/shared';
import { ButtonsRow } from './sign-in.styles';

const SignIn: FC = () => {
  const { login } = useAuth();
  const history = useHistory();
  const [isInvitedSignIn, setInvitedSignIn] = useState(false);
  const [title, setTitle] = useState('auth.title');
  const handleClick = async (isFredesnborgBolig?: boolean) => {
    try {
      await login(isFredesnborgBolig);
      history.push({ pathname: dashboardRoutes.INDEX });
    } catch (e) {
      captureException(e);
    }
  };
  const handleInviteMode = () => {
    setInvitedSignIn(true);
    setTitle('auth.invited.title');
  };
  const text = isInvitedSignIn ? '' : 'auth.text';

  return (
    <>
      <Intro text={text} title={title} />
      {isInvitedSignIn ? (
        <InvitedForm setTitle={setTitle} />
      ) : (
        <ButtonsRow>
          <Button
            label="auth.buttonLabel.general"
            onClick={() => {
              handleClick();
            }}
          />
          <Button
            label="auth.buttonLabel.fredensborgBolig"
            onClick={() => {
              handleClick(true);
            }}
          />
          <Button
            label="auth.invited.buttonLabel"
            onClick={handleInviteMode}
            variant="text"
          />
        </ButtonsRow>
      )}
    </>
  );
};
export default SignIn;
