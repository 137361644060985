import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import arrowRight from 'src/app/assets/arrow-right-white.svg';
import { Icon, Label, Link, NativeLink, Text } from './link-button.style';

type Props = {
  label: string;
  to: string;
  isExternal?: boolean;
  text?: string;
};

export const LinkButton: FC<Props> = (props) => {
  const { isExternal = false, label, text, to } = props;
  const [t] = useTranslation();
  const contentTemplate = (
    <>
      <div>
        <Label>{t(label)}</Label>
        {text && <Text>{t(text)}</Text>}
      </div>
      <Icon src={arrowRight} alt="" />
    </>
  );

  return isExternal ? (
    <NativeLink href={to} target="_blank">
      {contentTemplate}
    </NativeLink>
  ) : (
    <Link to={to}>{contentTemplate}</Link>
  );
};
