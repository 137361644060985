import '@fontsource/montserrat';
import { init as sentryInit } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import AppComponent from 'src/app/app.component';
import { getEnvValue } from 'src/app/shared';
import { name, version } from '../package.json';
import './index.css';

const environment = getEnvValue('ENV_NAME');
const sentryDsn = getEnvValue('SENTRY_DSN');

if (environment && sentryDsn) {
  const tracesSampleRate = getEnvValue('SENTRY_TRACES_SAMPLE_RATE');

  sentryInit({
    dsn: sentryDsn,
    environment,
    integrations: [new Integrations.BrowserTracing()],
    release: `${name}@${version}`,
    tracesSampleRate: tracesSampleRate ? Number(tracesSampleRate) : undefined,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>,
  document.getElementById('root'),
);
